import Headers from '../components/Headers';
import Footers from '../components/Footers';

export const PgContactUs=()=>{
    const sendAutomatedEmail = async () =>{
        alert("Sending automated email");
        try {
            const response = await fetch('/emailer.php', {
              method: 'GET',
            });
            const result = await response.text();
            alert(result); // Shows response from the server (e.g. "Email sent successfully!")
          } catch (error) {
            console.error("Error sending email:", error);
            alert("Failed to send email.");
          }
    }
    
    return(
        <div>
        <Headers />
        <div className='text-center'>
            <h1>Contact us</h1>
            <p>Email: <a href='mailto: info@hosting.nvarda.co.uk'>info@hosting.nvarda.co.uk</a></p>
            <button onClick={sendAutomatedEmail}>Send Automated Email</button>
        </div>
        <Footers />
        </div>
    );
}

export default PgContactUs;