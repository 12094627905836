import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import svgHelpIco from '../img/help-circle.svg';

export const RenderTooltip =({itemText})=>{
    // Function to render tooltip
    const funcRenderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {itemText}
      </Tooltip>
    );
  
    return (
          <OverlayTrigger
            placement="right" // Position of the tooltip
            delay={{ show: 250, hide: 400 }} // Delay for showing/hiding the tooltip
            overlay={funcRenderTooltip}
          >
            <span className="question-mark" style={{ cursor: 'pointer' }}>
              <img 
              src={svgHelpIco}
              style={{ width: "20px", height: "20px" }}
              alt=""
              />
              {/* {itemDisp} */}
            </span>
          </OverlayTrigger>
    );
}

export default RenderTooltip;