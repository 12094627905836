import React, { useState } from 'react';
import Footers from '../components/Footers';
import Headers from '../components/Headers';
// import GoogleLogincmp from '../components/_GoogleLogincmp';
import { registerUser, loginUser } from '../api/UserApi';
import { Link } from 'react-router-dom'; // Use useNavigate for redirection
import TermsAndConditionsModal from '../termsAndConditions/draft_1'; // New T&C component

const UserRegister = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState(''); // New state for retyping password
    const [firstname, setfirstname] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false); // New state for T&C acceptance
    const [showTerms, setShowTerms] = useState(false); // State to show/hide T&C modal
    // const navigate = useNavigate(); // Hook for navigation

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!firstname || !email || !password || !retypePassword || !termsAccepted) {
            setErrorMessage('Please fill in all required fields and accept the Terms and Conditions.');
            return;
        }

        if (password !== retypePassword) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        const response = await registerUser({ firstname, lastName, username, email, password });
        // console.log("Register response: ",response);
        if (response.message === 'Duplicate email entry') {
            setErrorMessage('An account associated with the email is found, Please try loging in');
        } else if (response.message === 'Duplicate username entry') {
            setErrorMessage('Username taken, try a different username');
        } else if(response.message === 'User added successfully.') {
            console.log("Registered successfully");
            loginUser(email, password);
            // Redirect to sign-in page upon successful registration
            // navigate('/Signin');
        } else {
            setErrorMessage('Unknown Error please trye again later, or contact customer support for more information');
        }
    };

    return (
        <div>
            <Headers />
            <div className="container py-5">
                <form onSubmit={handleRegister} className="mx-auto" style={{ maxWidth: '600px' }}>
                    {/* Uncomment Google login if necessary */}
                    {/* <div className="text-center mb-3">
                        <p>Sign up with:</p>
                        <GoogleLogincmp />
                    </div> */}

                    {errorMessage && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}

                    <div className="row mb-4">
                        <div className="col-md-6">
                            <div className="form-outline">
                                <input 
                                    type="text" 
                                    id="registerFirstName" 
                                    className="form-control" 
                                    required 
                                    value={firstname}
                                    onChange={(e) => setfirstname(e.target.value)}
                                />
                                <label className="form-label" htmlFor="registerFirstName">First Name*</label>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-outline">
                                <input 
                                    type="text" 
                                    id="registerLastName" 
                                    className="form-control" 
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                <label className="form-label" htmlFor="registerLastName">Last Name</label>
                            </div>
                        </div>
                    </div>

                    <div className="form-outline mb-4">
                        <input 
                            type="text" 
                            id="registerUsername" 
                            className="form-control" 
                            required 
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <label className="form-label" htmlFor="registerUsername">Username*</label>
                    </div>

                    <div className="form-outline mb-4">
                        <input 
                            type="email" 
                            id="registerEmail" 
                            className="form-control" 
                            required 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <label className="form-label" htmlFor="registerEmail">Email*</label>
                    </div>

                    <div className="form-outline mb-4">
                        <input 
                            type="password" 
                            id="registerPassword" 
                            className="form-control" 
                            required 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="form-label" htmlFor="registerPassword">Password*</label>
                    </div>

                    {/* Retype Password Input */}
                    <div className="form-outline mb-4">
                        <input 
                            type="password" 
                            id="retypePassword" 
                            className="form-control" 
                            required 
                            value={retypePassword}
                            onChange={(e) => setRetypePassword(e.target.value)}
                        />
                        <label className="form-label" htmlFor="retypePassword">Retype Password*</label>
                    </div>

                    {/* Terms and Conditions Checkbox */}
                    <div className="form-check mb-4">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="termsAndConditions"
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="termsAndConditions">
                            I agree to the <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => setShowTerms(true)}>Terms and Conditions</span>*
                        </label>
                    </div>

                    <button type="submit" className="btn btn-primary btn-block mb-4">
                        Sign Up
                    </button>

                    {/* Sign in option */}
                    <div className="text-center">
                        <p>Already have an account? <Link to="/Signin">Sign in here</Link></p>
                    </div>
                </form>
            </div>

            <Footers />

            {/* Terms and Conditions Modal */}
            {showTerms && <TermsAndConditionsModal onClose={() => setShowTerms(false)} />}
        </div>
    );
};

export default UserRegister;
