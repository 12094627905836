import Headers from '../components/Headers';
import Footers from '../components/Footers';
import Pricing from '../components/Pricing';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';

export const Home = () => {
    // console.log("Api hub: ",process.env.REACT_APP_CURRENT_ACCOUNT);
    return (
        <div>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />

            <Headers />

            {/* Hero Section */}
            <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                <h1 className="display-4 fw-bold">NVARDA</h1>
                <h2 className="text-muted">(Website still under development)</h2>
                <p className="fs-5 text-muted">
                    Welcome! Unlock your creativity and build your vision with a fast, easy-to-use platform tailored for web developers. 
                    Here, knowledge and experience are meant to be explored—start your journey and bring your ideas to life.
                </p>
                <Button variant="primary" className="mt-3" href='/Signin'>Sign in / Create Your Free Account</Button>
            </div>

            {/* Features Section */}
            <Container className="my-5">
                <Row className="text-center">
                    <Col md={4}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <h4 className="fw-bold">Fast Performance</h4>
                                <p>Our platform is optimized for speed, ensuring your website loads quickly and efficiently for all users.</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <h4 className="fw-bold">Easy sync</h4>
                                <p>Work locally and simply sync all files using the SFTP account</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <h4 className="fw-bold">Chat Support</h4>
                                <p>Get in touch to make a custom account, prices are Negotiable</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Pricing Section */}
            <center>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'center' }}>
                    <Pricing 
                        priceCardCost={"£0"}
                        priceCardCostPer={"month"}
                        priceCardStorage={"100 MB"}
                        priceCardSubDomain={"1"}
                        priceCardFtp={"1"}
                        priceCardStyleBorder={"primary"}
                        priceCardTitle={"Free plan"}
                        priceCardTitleStyle={"card-header py-3 bg-primary border-primary"}
                        priceCardPlanId={"free"}
                        priceCardEmails={"5"}
                    />
                    <Pricing 
                        priceCardCost={"1 ad"}
                        priceCardCostPer={"12 hour"}
                        priceCardStorage={"250 MB"}
                        priceCardSubDomain={"3"}
                        priceCardFtp={"3"}
                        priceCardStyleBorder={""}
                        // priceCardTitle={"Ad Plan"}
                        priceCardTitle={"Coming soon..."}
                        priceCardTitleStyle={"card-header py-3"}
                        priceCardPlanId={"Advert Plan"}
                        priceCardEmails={"15"}
                    />
                </div>
            </center>

            {/* Testimonials Section */}
            <Container className="my-5">
                <h2 className="text-center">What Our Users Say</h2>
                <Row className="mt-4 text-center">
                    <Col md={4}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <p>"NVARDA made it easy to create and host my website. I was able to get my portfolio up and running in no time!"</p>
                                <h6>- Alex T.</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <p>"The free plan offers so much for beginner developers. The platform is intuitive and offers everything I need."</p>
                                <h6>- Sarah M.</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <p>"Fast, reliable, and easy-to-use. I highly recommend NVARDA for anyone looking to create a website quickly!"</p>
                                <h6>- John P.</h6>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Call to Action Section */}
            <Container className="my-5 text-center">
                <h2>Start Building Today</h2>
                <p>Whether you're a beginner or an experienced developer, NVARDA has the tools you need to bring your ideas to life.</p>
                <Button variant="primary" className="mt-3" href='/Signin'>Sign in / Create Your Free Account</Button>
            </Container>

            <Footers />
        </div>
    );
}

export default Home;