import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const CookieViewer = () => {
    const [cookies, setCookies] = useState([]);
    const [error, setError] = useState(null);

    const fetchCookies = async () => {
        setError(null); // Reset error state before fetching
        try {
            const response = await fetch('https://www.api.hosting.nvarda.co.uk/api/cookies/get', {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
            });

            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const data = await response.json();
            // Convert the data to an array of key-value pairs
            console.log("[Test site] ", data);

            // Map the cookies into an array of key-value pairs
            const cookieArray = Object.entries(data).map(([key, value]) => ({ key, value }));
            setCookies(cookieArray); // Update cookies state
        } catch (error) {
            console.error('Error fetching cookies:', error);
            setError('Failed to fetch cookies: ' + error.message);
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center">Cookie Viewer</h1>
            <div className="text-center">
                <button className="btn btn-primary" onClick={fetchCookies}>
                    Get Cookies
                </button>
            </div>

            {error && <div className="alert alert-danger mt-4">{error}</div>}

            <div className="mt-4">
                <h2>Cookies:</h2>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Cookie Name</th>
                            <th>Cookie Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cookies.length > 0 ? (
                            cookies.map((cookie, index) => (
                                <tr key={index}>
                                    <td>{cookie.key}</td>
                                    {/* Directly display the cookie value, handle if it's not a string */}
                                    <td>{Array.isArray(cookie.value) ? cookie.value.join(', ') : cookie.value}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="2" className="text-center">No cookies available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CookieViewer;
