import React from "react";
import Footers from "../components/Footers";
import Headers from "../components/Headers";

function items_normal(product){
    return(
        <li className="list-group-item d-flex justify-content-between lh-condensed">
            <div>
                <h6>{product.name}</h6>
                <small className="text-muted">{product.description}</small>
            </div>
            <small className="text-muted">£{product.cost}</small>
        </li>
    );
};

// function items_promotional(product){
//     return(
//         <li className="list-group-item d-flex justify-content-between bg-light">
//             <div className="text-success">
//                 <h6 className="my-0">{product.name}</h6>
//                 <small>{product.description}</small>
//             </div>
//             <small className="text-success">£{product.cost}</small>
//         </li>
//     );
// };

function cartItems(item){
    return(
    <li className="list-group-item d-flex justify-content-between lh-condensed">
        <div>
            <h6>{item.name}</h6>
            <small className="text-muted">{item.description}</small>
        </div>
        <small className="text-muted">£{item.cost}</small>
    </li>
    );
}

export const RegisterPlan=()=>{
    // const urlParams = new URLSearchParams(window.location.search);
    const item_a = {
        name:"Experimenter plan",
        description:'100 MB web storage',
        cost:0,
    }

    // const item_b = {
    //     name:"Sub domain",
    //     description:'1 free subdomain',
    //     cost:0,
    // }

    return(
        <div>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"></link>
            <Headers />
                {/* <p>{urlParams.get("plan")}</p> */}
            <div className="row">
                <div className="col-md-4 order-md-2 mb-4">
                    <h4 className="d-flex justify-content-between align-items-center mb-3">
                        <span className="text-muted">Your cart</span>
                        <span className="badge badge-secondary badge-pill">2</span>
                    </h4>
                    <ul className="list-group mb-3">
                        {cartItems("CartStorage_PlansItems")}
                        {items_normal(item_a)}
                        {/* {items_normal(item_b)} */}
                        <li className="list-group-item d-flex justify-content-between">
                            <span>Total (GBP)</span>
                            <strong>£0</strong>
                        </li>
                    </ul>
                    <form className="card p-2">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Promo code"/>
                            <div className="input-group-append">
                                <button type="submit" className="btn btn-secondary">Redeem</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-8 order-md-1">
                    <h4 className="mb-3">Billing details</h4>
                    <form className="needs-validation" noValidate>
                        {/* <div className="row">
                            <div className="col-md-6 mb-3">
                                <label for="firstName">First name</label>
                                <input type="text" className="form-control" id="firstname" placeholder="Firstname" required/>
                                <div className="invalid-feedback">Valid first name is required.</div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label for="lastname">
                                    Last name <span className="text-muted">(Optional)</span>
                                </label>
                                <input type="text" className="form-control" id="lastname"/>
                            </div>
                        </div> */}
                        <div className="mb-3">
                            <label for="username">Username</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">@</span>
                                </div>
                                <input type="text" className="form-control" id="username" placeholder="Sign in to continue" required disabled/>
                                <div className="invalid-feedback" style={{width:'100%'}}>Username is required</div>
                            </div>
                        </div>
                        <button className="btn btn-primary btn-lg btn-block" type="submit" disabled>Checkout</button>
                        
                        <hr className="mb-4"/>
                    </form>
                </div>
            </div>
            <Footers />
        </div>
    );
};

export default RegisterPlan;