import React from "react";
import { Button, Card } from "react-bootstrap";
import RenderTooltip from './RenderToolTip';

export const Pricing=({priceCardCost, priceCardCostPer, priceCardStorage, priceCardSubDomain,
    priceCardFtp, priceCardStyleBorder, priceCardTitle, priceCardTitleStyle, priceCardPlanId,
    priceCardEmails})=>{

    return(
        <div>
            <Card border={priceCardStyleBorder} style={{width:'18rem'}}>
                <div className={priceCardTitleStyle}>
                    <h4 className="my-0 fw-normal">{priceCardTitle}</h4>
                </div>
                <Card.Body>
                    <Card.Title>{priceCardCost}<small className="text-muted fw-light">/{priceCardCostPer} <RenderTooltip itemText={`This plan costs ${priceCardCost} for every ${priceCardCostPer}. At the end of every ${priceCardCostPer} you would either need to pay/watch ${priceCardCost} to renew.`}/></small></Card.Title>
                    <Card.Text>{priceCardStorage} storage <RenderTooltip itemText={`This plan comes with a maximum of ${priceCardStorage} storage.`}/></Card.Text>
                    <Card.Text>{priceCardSubDomain} subdomain <RenderTooltip itemText={`This plan comes with a maximum of ${priceCardSubDomain} subdomain from antp.fun (youdomain.antp.fun).`}/></Card.Text>
                    <Card.Text><s>{priceCardFtp} FTP account</s> <RenderTooltip itemText={`This account comes with a maximum of ${priceCardFtp} sftp account, albeit it does need to be manually configured.`}/></Card.Text>
                    <Card.Text>No page limit <RenderTooltip itemText={'This account is restricted by storage, not page limit.'}/></Card.Text>
                    <Card.Text>No watermark <RenderTooltip itemText={"There are no watermarks in this plan"}/></Card.Text>
                    <Card.Text>{priceCardEmails} custom email forwarding <RenderTooltip itemText={`This account can accomodate upto ${priceCardEmails} email forwarding. (For example, an email sent to info@yourdomain.antp.fun will be redirected to youremail@gmail.com)`}/></Card.Text>
                    <Button variant="outline-primary" onClick={()=>{
                        window.open("/RegisterPlan",'_self')
                    }}>Select</Button>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Pricing;